import { CombinedState, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { AppThunk, AppDispatch } from "./store";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import last from "lodash/last";
import partition from "lodash/partition";
import { IAppConfig } from "../utils/appSetup";

import { IFeature, IRegion, IRegionFilter, IRegionOrIRegionFilter } from "../ts/interfaces";
import { trackAction, trackActionType } from "../utils/eTracker";
import { getFlattenedRegions, isRegionFilter, isSingleRegion } from "../utils/helpers";
import { setActivePickerFeature, setActiveLayerByRegionOrRegionFilter, setActiveRegionOrRegionFilter } from "./uiSlice";
import { resetAllFilters, stopEditRegionFilter } from "./filtersSlice";

interface IRegionOrIRegionFilterPayload {
  regionOrRegionFilter: IRegionOrIRegionFilter;
}

interface ITopLowRegionsCountPayload {
  topRegionsCount?: number | null;
  lowRegionsCount?: number | null;
}

interface IRegionOrRegionFilterState {
  chosen: Array<IRegionOrIRegionFilter>;
  lastChosen: IRegionOrIRegionFilter | null;
  topRegionsCount: number | null;
  lowRegionsCount: number | null;
}

// The real "initial" state comes from the preloadedState below
// which is based on the dataConfig from `data-js-wk-config`.
// However, TypeScript requirements force us to still have valid `initialState`
// for `createSlice`. This is just useless dummy data that will never be used at runtime
// See https://github.com/reduxjs/redux-toolkit/issues/873#issuecomment-862147028
const initialState: IRegionOrRegionFilterState = {
  chosen: [],
  // This is only set when the last item in chosen array gets removed.
  // Otherwise it is empty.
  // This way the last chosen item can be reset if needed.
  lastChosen: null,

  topRegionsCount: null,
  lowRegionsCount: null
};

export const getPreloadedState = (appConfig: IAppConfig): IRegionOrRegionFilterState => {
  return {
    chosen: appConfig.regionsAndRegionFilters,
    // This is only set when the last item in chosen array gets removed.
    // Otherwise it is empty.
    // This way the last chosen item can be reset if needed.

    lastChosen: null,

    topRegionsCount: appConfig.topRegionsCount || null,
    lowRegionsCount: appConfig.lowRegionsCount || null
  };
};

const regionsOrRegionFiltersSlice = createSlice({
  name: "regionsOrRegionFilters",
  initialState: initialState,
  reducers: {
    chooseRegionOrRegionFilter(state, action: PayloadAction<IRegionOrIRegionFilterPayload>) {
      const { regionOrRegionFilter } = action.payload;
      state.chosen.push(regionOrRegionFilter);
      state.lastChosen = null;
    },

    unchooseRegionOrRegionFilter(
      state,
      action: PayloadAction<{
        // the type of feature id as of leaflet can possibly be string | undefined: http://definitelytyped.org/docs/geojson--geojson/interfaces/geojson.feature.html#id
        regionId: number | string | undefined;
      }>
    ) {
      const { regionId } = action.payload;
      const newChosen = state.chosen.filter((c) => c.id !== regionId);
      if (isEmpty(newChosen)) {
        state.lastChosen = get(state.chosen, "[0]", "");
      } else {
        state.lastChosen = null;
      }
      state.chosen = newChosen;
    },

    resetLastRegionOrRegionFilter(state) {
      const lastChosen = state.lastChosen as IRegionOrIRegionFilter;
      state.chosen.push(lastChosen);
      state.lastChosen = null;
    },

    resetAllRegionsOrRegionFilters(state) {
      state.lastChosen = get(state.chosen, "[0]", "");
      state.chosen = [];
    },

    replaceRegionFilter(
      state,
      action: PayloadAction<{
        oldRegionFilter: IRegionFilter;
        newRegionFilter: IRegionFilter;
      }>
    ) {
      const { oldRegionFilter, newRegionFilter } = action.payload;
      const regionFilterIndex = state.chosen.findIndex((el) => el.id === oldRegionFilter.id);
      if (regionFilterIndex >= 0) {
        state.chosen[regionFilterIndex] = newRegionFilter;
      }
    },

    setTopLowRegionsCount(state, action: PayloadAction<ITopLowRegionsCountPayload>) {
      const { topRegionsCount, lowRegionsCount } = action.payload;
      if (topRegionsCount !== undefined) {
        state.topRegionsCount = topRegionsCount;
      }
      if (lowRegionsCount !== undefined) {
        state.lowRegionsCount = lowRegionsCount;
      }
    }
  }
});

export const chooseRegionOrRegionFilter = (regionOrRegionFilter: IRegionOrIRegionFilter): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(
    regionsOrRegionFiltersSlice.actions.chooseRegionOrRegionFilter({
      regionOrRegionFilter: regionOrRegionFilter
    })
  );
  dispatch(setActiveLayerByRegionOrRegionFilter(regionOrRegionFilter));
  dispatch(setActiveRegionOrRegionFilter(null));
  dispatch(setActivePickerFeature(null));
  dispatch(resetAllFilters());

  trackAction(trackActionType.REGION_SELECTION, regionOrRegionFilter.name);
};

export const unchooseRegionOrRegionFilterById = (
  regionOrRegionFilterId: string | number | undefined
): AppThunk => async (dispatch: AppDispatch, getState: () => CombinedState<RootState>) => {
  const { regionsOrRegionFilters, filters } = getState();
  const lastChosenRegionOrRegionFilter = last(
    regionsOrRegionFilters.chosen.filter((item) => item.id !== regionOrRegionFilterId)
  );

  dispatch(
    regionsOrRegionFiltersSlice.actions.unchooseRegionOrRegionFilter({
      regionId: regionOrRegionFilterId
    })
  );

  if (filters.regionFilterInEditing?.id === regionOrRegionFilterId) {
    dispatch(stopEditRegionFilter());
  }

  if (lastChosenRegionOrRegionFilter) {
    dispatch(setActiveLayerByRegionOrRegionFilter(lastChosenRegionOrRegionFilter));
  }

  dispatch(setActivePickerFeature(null));
};

export const unchooseRegionOrRegionFilter = (
  regionOrRegionFilterOrFeature: IRegionOrIRegionFilter | IFeature
): AppThunk => unchooseRegionOrRegionFilterById(regionOrRegionFilterOrFeature.id);

export const resetLastRegionOrRegionFilter = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(regionsOrRegionFiltersSlice.actions.resetLastRegionOrRegionFilter());
};

export const resetAllRegionsOrRegionFilters = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(regionsOrRegionFiltersSlice.actions.resetAllRegionsOrRegionFilters());

  dispatch(resetAllFilters());
};

export const replaceRegionFilter = (oldRegionFilter: IRegionFilter, newRegionFilter: IRegionFilter): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(
    regionsOrRegionFiltersSlice.actions.replaceRegionFilter({
      oldRegionFilter,
      newRegionFilter
    })
  );
};

export const setTopLowRegionsCount = (values: ITopLowRegionsCountPayload): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(regionsOrRegionFiltersSlice.actions.setTopLowRegionsCount(values));
};

export const selectChosenRegionsOrRegionFilters = (state: RootState) => state.regionsOrRegionFilters.chosen;

// Returns either chosen (if available) or lastChosen as fallback
export const selectUsedRegionsOrRegionFilters = (state: RootState) =>
  isEmpty(state.regionsOrRegionFilters.chosen) && state.regionsOrRegionFilters.lastChosen !== null
    ? [state.regionsOrRegionFilters.lastChosen]
    : state.regionsOrRegionFilters.chosen;

export const selectTopRegionsCount = (state: RootState) => state.regionsOrRegionFilters.topRegionsCount;
export const selectLowRegionsCount = (state: RootState) => state.regionsOrRegionFilters.lowRegionsCount;
export const selectHasTopXorLowRegionsCount = (state: RootState) =>
  !!state.regionsOrRegionFilters.topRegionsCount !== !!state.regionsOrRegionFilters.lowRegionsCount;
export const selectIsTopLowRegionsCountUseful = (state: RootState) =>
  isSingleRegion(state.regionsOrRegionFilters.chosen);

export const createRegionsOrRegionFiltersMemoizedSelectors = () => {
  return {
    /**
     * Only return flattened regions from list of regions and region filters
     * Using a memoized selector, because `reduce` returns a new reference
     * for each result (immutable)
     */
    selectChosenRegions: createSelector(
      (state: RootState) => state.regionsOrRegionFilters.chosen,
      (regionsOrRegionFiltersChosen) => {
        const flattenedIndicators = getFlattenedRegions(regionsOrRegionFiltersChosen);

        return flattenedIndicators;
      }
    ),
    /**
     * Returns a tuple of two arrays, first array containing all chosen region-filters,
     * second array containing all directly chosen regions.
     */
    selectChosenRegionsAndRegionFilters: createSelector(
      (state: RootState) => state.regionsOrRegionFilters.chosen,
      (regionsOrRegionFiltersChosen) =>
        partition(regionsOrRegionFiltersChosen, (el) => isRegionFilter(el)) as [IRegionFilter[], IRegion[]]
    )
  };
};

export default regionsOrRegionFiltersSlice.reducer;
