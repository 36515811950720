import { ReactElement, useCallback } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveRenderer,
  selectActiveSelection,
  selectComparisonYear,
  selectChosenIndicatorsOrTopics,
  selectStatisticType
} from "../../redux/indicatorsOrTopicsSlice";
import { selectChosenRegionsOrRegionFilters } from "../../redux/regionsOrRegionFiltersSlice";
import { selectChosenSingleYear, selectChosenYears, setAreYearsPlaying } from "../../redux/yearsSlice";
import {
  selectActiveOutline,
  selectIncludeComparisonYear,
  selectCompareGenerations,
  selectHighlightedElements
} from "../../redux/uiSlice";
import { getFriendlyUrl } from "../../utils/FriendlyUrlHandler";
import ns from "../../utils/namespace";
import { useGetSvgPath } from "../../utils/svgPath";
import Icon from "../atoms/Icon";
import UnorderedList from "../atoms/UnorderedList";
import DownloadLink from "./DownloadLink";
import Dropdown from "./Dropdown";

interface Props {
  title?: string;
  className?: string;
}

export default function DownloadDropdown({ title, className }: Props): ReactElement {
  const dispatch = useDispatch();

  const statisticType = useSelector(selectStatisticType);
  const activeSelection = useSelector(selectActiveSelection);
  const activeRenderer = useSelector(selectActiveRenderer);
  const activeMapOutline = useSelector(selectActiveOutline);
  const chosenRegionsOrRegionFilters = useSelector(selectChosenRegionsOrRegionFilters);
  const chosenIndicatorsOrTopics = useSelector(selectChosenIndicatorsOrTopics);
  const chosenYears = useSelector(selectChosenYears);
  const chosenSingleYear = useSelector(selectChosenSingleYear);
  const comparisonYear = useSelector(selectComparisonYear);
  const includeComparisonYear = useSelector(selectIncludeComparisonYear);
  const compareGenerations = useSelector(selectCompareGenerations);
  const highlightedElements = useSelector(selectHighlightedElements);

  const downloadIcon = (
    <Icon className={classNames(ns("btn__icon"), ns("download-dropdown__icon"))} width={24} height={24} fill="none">
      <use xlinkHref={useGetSvgPath("#download")}></use>
    </Icon>
  );

  const currentFriendlyUrl = getFriendlyUrl(
    statisticType,
    activeSelection,
    activeRenderer,
    activeMapOutline,
    chosenRegionsOrRegionFilters,
    chosenIndicatorsOrTopics,
    chosenYears,
    chosenSingleYear,
    comparisonYear,
    includeComparisonYear,
    compareGenerations
  );
  const exportUrlBase = `/data-api/rest/export/${currentFriendlyUrl}`;

  const concatenatedHighlightedElements =
    highlightedElements.length === 0 ? "" : "&highlight=" + highlightedElements.join(",");

  const handleOpen = useCallback(() => {
    dispatch(setAreYearsPlaying(false));
  }, [dispatch]);

  return (
    <Dropdown
      toggleButton={{
        label: "Download",
        icon: downloadIcon,
        className: ns("btn--primary download-dropdown__button")
      }}
      panel={{
        className: ns("download-dropdown__panel"),
        title: title
      }}
      className={classNames(ns("download-dropdown"), className)}
      onOpen={handleOpen}
    >
      {({ closeDropdown }) => {
        switch (activeRenderer) {
          case "BAR_CHART":
          case "LINE_CHART":
          case "MIGRATION_CHART":
          case "RADAR_CHART":
          case "PYRAMID_CHART":
          case "DISTRIBUTION_CHART":
          case "MAP":
            return (
              <UnorderedList className={ns("download-dropdown__options-list")}>
                <li className={ns("download-dropdown__options-list-item")}>
                  <DownloadLink
                    href={`${exportUrlBase}.png?download=true&width=800${concatenatedHighlightedElements}`}
                    label="PNG"
                    onClick={closeDropdown}
                  />
                </li>
                <li className={ns("download-dropdown__options-list-item")}>
                  <DownloadLink
                    href={`${exportUrlBase}.svg?download=true${concatenatedHighlightedElements}`}
                    label="SVG"
                    onClick={closeDropdown}
                  />
                </li>
                <li className={ns("download-dropdown__options-list-item")}>
                  <DownloadLink
                    href={`${exportUrlBase}.pdf?download=true${concatenatedHighlightedElements}`}
                    label="PDF"
                    onClick={closeDropdown}
                  />
                </li>
              </UnorderedList>
            );
          case "TABLE":
            return (
              <UnorderedList className={ns("download-dropdown__options-list")}>
                <li className={ns("download-dropdown__options-list-item")}>
                  <DownloadLink href={`${exportUrlBase}.csv?download=true`} label="CSV" onClick={closeDropdown} />
                </li>
                <li className={ns("download-dropdown__options-list-item")}>
                  <DownloadLink href={`${exportUrlBase}.xlsx?download=true`} label="XLSX" onClick={closeDropdown} />
                </li>
                <li className={ns("download-dropdown__options-list-item")}>
                  <DownloadLink href={`${exportUrlBase}.pdf?download=true`} label="PDF" onClick={closeDropdown} />
                </li>
                <li className={ns("download-dropdown__options-list-item")}>
                  <DownloadLink href={`${exportUrlBase}.json?download=true`} label="JSON" onClick={closeDropdown} />
                </li>
              </UnorderedList>
            );
          default:
            return null;
        }
      }}
    </Dropdown>
  );
}
