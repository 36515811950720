import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import { AppThunk, AppDispatch } from "./store";
import { IAppConfig } from "../utils/appSetup";
import { getAvailableYears } from "../utils/helpers";
import { trackActionType, trackAction } from "../utils/eTracker";

interface IYearsState {
  chosen: Array<number>;
  chosenSingleYear: number | null;
  areYearsPlaying: boolean;
}

// The real "initial" state comes from the preloadedState below
// which is based on the dataConfig from `data-js-wk-config`.
// However, TypeScript requirements force us to still have valid `initialState`
// for `createSlice`. This is just useless dummy data that will never be used at runtime
// See https://github.com/reduxjs/redux-toolkit/issues/873#issuecomment-862147028
const initialState: IYearsState = {
  chosen: [],
  chosenSingleYear: null,
  areYearsPlaying: false
};

export const getPreloadedState = (appConfig: IAppConfig): IYearsState => {
  return {
    chosen: appConfig.years,
    chosenSingleYear: null,
    areYearsPlaying: false
  };
};

const yearsSlice = createSlice({
  name: "years",
  initialState: initialState,
  reducers: {
    chooseYears(state, action: PayloadAction<{ years: Array<number> }>) {
      const { years } = action.payload;
      state.chosen = years;
    },
    chooseSingleYear(state, action: PayloadAction<{ year: number | null }>) {
      const { year } = action.payload;
      state.chosenSingleYear = year;
    },
    setAreYearsPlaying(state, action: PayloadAction<{ areYearsPlaying: boolean }>) {
      const { areYearsPlaying } = action.payload;
      state.areYearsPlaying = areYearsPlaying;
    }
  }
});

export const chooseYears = (years: Array<number>): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(yearsSlice.actions.chooseYears({ years: years }));
  if (years.length === 1) {
    trackAction(trackActionType.YEAR_SELECTION, years[0] + "");
  } else if (years.length > 1) {
    trackAction(trackActionType.YEAR_SELECTION, years[0] + "-" + years[years.length - 1]);
  }
};

export const chooseSingleYear = (year: number | null): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(yearsSlice.actions.chooseSingleYear({ year: year }));
  trackAction(trackActionType.YEAR_SELECTION, year + "");
};

export const setAreYearsPlaying = (areYearsPlaying: boolean): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    yearsSlice.actions.setAreYearsPlaying({
      areYearsPlaying: areYearsPlaying
    })
  );
};

export const selectChosenYears = (state: RootState) => {
  const activeSelection = state.indicatorsOrTopics.activeSelection;
  const renderer = state.indicatorsOrTopics.activeRenderer;
  const chosenYears = state.years.chosen;
  const selection =
    activeSelection && renderer !== "TABLE"
      ? [activeSelection]
      : isEmpty(state.indicatorsOrTopics.chosen)
      ? []
      : state.indicatorsOrTopics.chosen;

  if (selection.length > 0) {
    const availableYears = getAvailableYears(selection, state.appConfig.types);

    // if stored chosenYears is not a subset of availableYears (thereby not valid) return instead availableYears
    if (isEmpty(chosenYears) || !chosenYears.every((year) => availableYears.includes(year))) {
      console.log({ availableYears });

      return availableYears;
    }
  }

  console.log({ chosenYears });
  return chosenYears;
};

// In difference to selectChosenSingleYear below this selector returns the acutal stored chosenSingleYear without any further computing
export const selectBareChosenSingleYear = (state: RootState) => {
  return state.years.chosenSingleYear;
};

export const selectChosenSingleYear = (state: RootState) => {
  const activeSelection = state.indicatorsOrTopics.activeSelection;
  const renderer = state.indicatorsOrTopics.activeRenderer;
  const chosenSingleYear = state.years.chosenSingleYear;
  const selection =
    activeSelection && renderer !== "TABLE"
      ? [activeSelection]
      : isEmpty(state.indicatorsOrTopics.chosen)
      ? []
      : state.indicatorsOrTopics.chosen;

  if (selection.length > 0) {
    const availableYears = getAvailableYears(selection, state.appConfig.types);

    // if stored chosenSingleYear is not a subset of availableYears (thereby not valid) return instead last availableYears
    if (chosenSingleYear === null || !availableYears.includes(chosenSingleYear)) {
      if (availableYears.length) {
        return last(availableYears) as number;
      } else {
        return null;
      }
    }
  }
  return chosenSingleYear;
};

export const selectAreYearsPlaying = (state: RootState) => state.years.areYearsPlaying;

export default yearsSlice.reducer;
